<template>
  <section class="section">
    <h1 class="title">Краткая справка</h1>

    <template v-if="isEditor">
      <div class="content">
        <h2 class="subtitle">Календарь</h2>
        <p>
          В разделе КАЛЕНДАРЬ мы добавляем событие в календарь, заполнив
          “Название”, “Дату начала мероприятия”, “Дату окончания мероприятия”,
          “Место проведения” и “Вид спорта”, "Описание".
        </p>
        <p>
          После создания события оно ещё не активно (перед название желтая
          кнопка с замком), чтобы активировать нажать на кнопку.
        </p>
        <p>
          РЕЗУЛЬТАТЫ будут отображать “Дату мероприятия”, “Дисциплину”,
          “Спортсмена”/"Описание" и его “Результат”. В описании можно указывать,
          например Иванов Иван (если нет в базе) или Россия - Уругвай, если
          командный спорт
        </p>
        <p>
          СОСТАВ КОМАНДЫ - добавляем/удаляем спортсменов из имеющихся в базе.
          Если на данный момент спортсмена нет, открываем меню Спортсмены в
          новой вкладке и добавляем данные, после сохранения, спортсмена можно
          будет добавить в состав команды и к результату.
        </p>
      </div>

      <div class="content">
        <h2 class="subtitle">Спортсмены</h2>
        <p>
          В разделе содержатся все спортсмены имеющиеся на данный момент в базе.
          Создаются по тому же принципу.
        </p>
      </div>

      <b-notification
        type="is-danger is-light"
        aria-close-label="Close notification"
        role="alert"
      >
        Запрещено удалять события, спортсменов, при необходимости можно запись
        заблокировать, нажав на кнопку с замком перед названием.
        <br />
        Данные останутся в базе, но не будут видны на сайте.
      </b-notification>

      <div class="content">
        <h3 class="subtitle">Остальные пункты меню</h3>
        <p>
          ВИДЫ СПОРТА, добавить / отредактировать виды спорта. Для каждого вида
          добавляем/редактируем дисциплины.
        </p>
      </div>
    </template>

    <template v-else>
      <div class="content">
        <h2 class="subtitle">Календарь</h2>
        <p>
          Раздел содержит все спортивные события. Поиск и сортировка по
          названию, датам, месту проведения, виду спорта.<br />
          В статусе желтый замок означает, что событие не отображается на сайте.
        </p>
        <p>
          Для скачивания списка событий в excel нажать кнопку Скачать, выбрать
          диапазон дат.
        </p>

        <h2 class="subtitle">Спортсмены</h2>
        <p>
          В разделе содержатся все спортсмены имеющиеся на данный момент в базе.
          Поиск по фамилии, званию, региону, виду спорта.
        </p>
        <p>Для скачивания pdf, найти нужного, нажать кнопку Скачать.</p>
      </div>
    </template>
  </section>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Help",

  computed: {
    ...mapGetters({
      isEditor: "auth/isEditor",
    }),
  },

  created() {
    document.title = "Справка";
  },
};
</script>
